.team-container .teams .team{
    width: calc(100% / 2 - 20px);
    
    
}
.team-container{
    margin-bottom: 20px;
}

.team-container .teams{
    padding-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    gap: 20px;
    margin: auto;
}

@media screen and (max-width: 1020px){
    .team-container .teams{
        width: 100%;
    }
}

@media screen and (max-width: 768px){
    .team-container .teams{
        width: 95%;
    }
    .team-container .teams .team{
        width: 100%;
        margin: auto;
    }
}
