.councils-container{
    padding: 15px 120px;
    background-color: var(--bg-primary);
}
.councils-container .council-box{
    background-color: var(--light);
    border: 1px solid var(--bg-light);
    border-radius: 7px;
    padding: 15px;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
}

.council-box .council-text{
    padding: 10px 0 25px 15px;
    max-width: 500px;
}

.councils-container .council-box .council-example i{
    font-size: 2rem;
    width: 100px;
    color: green;
}

.council-img{
    width: 90%;
    height: 300px;
}

.council-img img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:687px) {
    .councils-container{
        padding: 15px;
    }
    .councils-container .council-box{
        padding: 0;
        border: none;
    }
    .councils-container .council-box .council-example{
        display: flex;
        flex-direction: column;
    }

    .council-box .council-text{
        font-size: 1.5rem;
    }
    .councils-container .council-box .council-example i{
        transform: rotate(90deg);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .council-img{
        width: 100%;
        padding: 0;
    }
}