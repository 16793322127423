.contacts-container .contacts{
    padding-top: 20px;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
}
.contacts-container .contacts .contact{
    width: calc(100% / 2 - 100px);
    display: flex;
    background-color: var(--bg-primary);
    color: var(--light);
    padding: 30px 0 20px 20px;
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width:768px){
    .contacts-container .contacts .contact{
        width: calc(100%  - 40px);
        margin: auto;
    }
}