.testimonials{
    flex-wrap: wrap;
    padding-top: 25px;
    gap: 15px;
    justify-content: center;
}

.testimonials .testimonial{
    width: calc(100% / 4 - 30px);
}
 .link{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}
@media screen and (max-width:1140px){
    .testimonials .testimonial{
        width: calc(100% / 2 - 15px);
    }
}

@media screen and (max-width:768px){
    .testimonials .testimonial{
        width: calc(100% - 15px);
    }
}