.testimonial-card{
    height: 350px;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.testimonial-card .infos{
    gap: 20px;
}

.testimonial-card .infos h4{
    font-size: 1.5rem;
    font-weight: 800;
}

.testimonial-card .infos i{
    width: 20px ;
    height: 20px;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    color: var(--light);
}
.testimonial-card .card-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.testimonial-card .card-img{
    margin: 25px 0 15px 0;
    height: 100px ;
    width: 100px;
}

@media screen and (max-width:768px){
    .testimonial-card{
        height: auto;
    }
}