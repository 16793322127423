.footer-container{
    padding: 10px;
    background-color: var(--bg-primary);
    color: var(--light);
}
.footer-container a{
    color: var(--light);
}

.footer-container .elements{
    display: flex;
    align-items:flex-start;
    gap: 50px;
    flex-wrap: wrap;
}
.footer-container .footer-list{
    align-items: flex-start;
    flex-wrap: wrap; 
    
}
.footer-container .footer-title{
    line-height: 35px;
    
}
.footer-container .footer-title .title{
   
    font-size: 2rem;
}

@media screen and (max-width:768px){
    .footer-container .elements{
        gap: 20px;
    }
    .footer-container .footer-title .title{
   
        font-size: 1.7rem;
    }
    .developper , .copyright{
        font-size: .999rem;
    }
}