.contactCard .icon{
    font-size: 2.6rem;
    color: green;
    margin-right: 15px;
}

.contactCard .title{
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 5px;
    color: var(--light);
}

.contactCard .description{
    color: var(--light);
}
.contactCard .description{
    font-size: 1.1rem;
}

@media screen and (max-width:768px){
    .contactCard .title{
        font-size: 1rem;
    font-weight: 500;
    }
}