.navbar{
    background-color: var(--bg-light);
    padding: 15px 0 10px 15px;
    position: fixed;
    width: 100vw;
    box-shadow: 0 0 10px 15px rgba(0,0,0,0.1);
    z-index: 999;
}

.navbar .logo{
    height: 50px;
    width: 50px;
    background-color:inherit;
    margin-right: 50px;
    
    
}

.navbar .logo img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.navbar .menu-container{
    gap: 25px ;
    border-left: 5px solid var(--bg-primary);
    padding-left: 50px ;
    height: 70px;
}
.navbar .menu-container li{
    transition: all .4s linear ;
}
.navbar .menu-container li a{
    font-size: .6em;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--bg-primary);
}
.navbar .menu-container li.border{
    border-bottom: 2px solid var(--bg-primary);
    padding: 0 20px 5px 20px;
}
.navbar .form {
    flex: 1 ;
    margin: 0 0 0 120px;
    width: 00px;
}

.navbar .form input{
    padding: 15px;
    border: none;
    border-radius: 25px;
    outline-color: var(--bg-primary);
}
 .btn{
    padding: 15px;
    border-radius: 25px;
    border: none;
    background-color: var(--bg-primary);
    color: var(--light);
    margin-left: 25px;
    font-size: 1rem;
    cursor: pointer;
}

.navbar .toggle-bars{
    display: none;
}
.icon-container{
    margin-right: 10%;
}

.icon-container a{
    font-size: 1.5rem;
    margin-right: 15px;
    padding: 7px;
    border-radius: 50%;
    color: #fff;
    background-color: var(--bg-primary);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

}

@media screen and (max-width: 1300px){
    .navbar .form{
        visibility: hidden;
    }
    .nav-elements{
        width: 100vw;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px){
    .navbar{
        justify-content: space-between;
    }
    .navbar .logo{
        margin: 0;
    }
    .navbar .menu-container{
        display: none;
    }
    .navbar .menu-container.active{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        position: absolute;
        left: 0;
        top: 74px;
        height: 100vh;
        width: 180px;
        padding: 15px;
        background-color: var(--bg-dark);
    }
    .navbar .menu-container.active li a{
        color: var(--light);
    }

    .navbar .form{
        margin: 0 0 0 15px;
        max-width: 400px;
    }

    .navbar .form input{
        width: auto;
    }

    .navbar .toggle-bars{
        display: inline-block;
        margin-right: 10%;
        font-size: 1.5rem;
        cursor: pointer;
    }
    .icon-container{
        display: none;
    }

    .icon-container.show{
        display: block;
        position: absolute;
        top: 800%;
        left: 4%;
    }

    .icon-container a{
        width: 20px;
        height: 20px;
        font-size: 1.2rem;
    }

    
}

@media screen and (max-width: 500px){
    .navbar .form{
        display: none;
    }
}