.services-container{
    margin: 20px auto;
}
 .announce-text{
    max-width: 450px;
    margin-left: 15%;
    padding: 10px 10px 10px 20px  ;
    font-size: 1.5rem;
    font-style: italic;
    color: #6a6767;
    background-color: var(--light);
    border-left: 5px solid var(--bg-primary);
}
.only-main-page .text{
    font-size: 2rem;
    margin: 25px auto;
    color: var(--bg-primary);
    width: 100%;
    border-bottom: 3px solid var(--bg-primary);
    padding:15px;
    max-width: 90%;
    background-color: goldenrod;
}
.services-container .services{
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    margin-top: 20px;
}
.services-container .services .service{
    width: calc(100% / 3 - 25px);
    
}
 .services-bottom{
    align-items: center;
    justify-content: center;
    display: flex;
    width: 70%;
    margin: auto;
   padding-top: 30px;
}

.services-bottom a{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    
}
.services-bottom i{
    transition: margin-left .5s linear;
}

.services-bottom a:hover i{
    margin-left: 20px;
}
.services-bottom h3{
    max-width: 400px;
}
@media screen and (max-width:999px){
    .services-container .services .service{
        width: calc(100% / 2 - 25px);
    }
    .services-bottom{
        width: 100%;
    }
    .only-main-page .text{
        font-size: 1.3rem;
    }
}

@media screen and (max-width:687px) {
    .announce-title{
        font-size: 1.4rem;
    }
    .services-container .services .service{
        width: 95%;
        margin: auto;
    }
    .services-bottom{
        width: 95%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: auto;
        gap: 15px;
        padding: 20px 0;
    }
}
