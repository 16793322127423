.landing-container{
    padding-top: 60px;
}
.landing-container .landing-image{
    width: 100vw;
    height: 70vh;
}

.landing-container .landing-image img{
    width: 100%;
    height: 100%;
}


@media screen and (max-width:768px) {
    .landing-container .landing-image{
        height: 50%;
    }

}