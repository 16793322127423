.serviceCard .card-img{
    width: 100%;
    height:200px ;
    position: relative;
}
.serviceCard .card-img img{
    width: 100%;
    height: 100%;
}
.serviceCard{
    height: 400px;
    
}
.card-title{
    background-color:var(--light);
    position:absolute;
    width: 300px;
    top: 50%;
    height: 70px;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;

}
.card-description{
    padding:20px;
    font-size: 1.1rem;
}
@media screen and (max-width:999px){
    .serviceCard{
        height: 480px;
    }
}
@media screen and (max-width:687px){
    .serviceCard{
        height: auto;
    }
    .card-title{
        margin-top: -85px;
        top: 92%;
        height: 70px;
        left: 50%;
        transform: translate(-50%, 50%);
        font-size: 1.3rem;
    }

    .card-description{
        font-size: 1rem;
    }
}