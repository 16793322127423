
:root{
  --bg-primary:#4e3480
  ;
  --bg-dark:#260941;
  --bg-light:#f1f1f1;
  --light:#fff;

}

*{
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: content-box;
  font-family: monospace sans-serif;
  text-decoration: none;
}
body{
  background-color: var(--bg-light);
}

.flex{
  display: flex;
  align-items: center;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.flex-start{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-around{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-centerWrap{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-betweenWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.announce-title{
  text-align: center;
  font-size: 5rem;
  padding: 20px;
  color: var(--bg-primary);
  
}

.card{
    box-shadow: 10px 10px  10px 10px rgba(0, 0, 0, 0.1) ;
    border-radius: 5px;
    background-color:var(--bg-light);
}

@media screen and (max-width: 768px){
  .announce-title{
    font-size: 1.5rem;
    padding: 10px;
  }

  .card{
    width: 90%;
    margin: auto;
  }
}
