.team-card{
    display: flex;
    justify-content: space-between;
}

.team-card div{
    width: calc(100% / 2 - 30px);
    height: 300px;
}

.team-card .team-img img{
    width: 100%;
    height: 100%;
}

.team-card .team-details{
    margin: 10px 10px 0 0;
}

.team-card .team-details h4{
    font-size: 2rem;
}

.team-card .team-details .card-description{
    font-size: 1.1rem;
}

@media screen and (max-width: 768px) {
    .team-card div{
        width: 95%;
        margin: auto;
        height: auto;
        text-align: left;
    }

    .team-card .team-details{
        padding:10px;
    }

    .team-card{
        flex-direction: column;
        text-align: center;
        
    }

    .team-card .team-img{
        width: 100%;
    }
   
}